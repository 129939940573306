import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import FancyLineHeading from '~components/LayoutComponents/FancyLineHeading'
import scssVars from '~components/scss-variables'
import { GuestRoute } from '~components/Routes'
import Layout from '~components/Layout'
import LogInForm from '~components/Authentication/LogInForm'
import SEO from '~components/seo'

import '~components/all.scss'

const LoginHeader = styled(FancyLineHeading)`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    &:after {
      height: 0.6em;
      left: 20%;
    }
  }
`

const SubHeader = styled.h2`
  font-weight: 600;
  font-size: 24px;
`

const BodyCopy = styled.div`
  p, li {
    font-size: 16px;
  }
  p {
    margin-bottom: 5px;
  }
  ul {
    padding-left: 20px !important;
  }
  li {
    line-height: 1.675;
  }
  a {
    font-style: italic;
    font-weight: 500;
    text-decoration: underline;
  }
`

const BodyAddendum = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px !important;
`

export default function LogInPage ({ location }) {
  return (
    <GuestRoute>
      <Layout>
        <SEO title="Login" pathname="/log-in" />
        <main>
          <section className="text-clr-sambuca pt-5 pb-5">
            <Container>
              {/* TODO: Use section header from home page */}
              <LoginHeader type="h1" className="mb-4">Log In</LoginHeader>
              <Row>
                <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 1 }}>
                  <SubHeader>Log in for a Better Experience</SubHeader>
                  <BodyCopy>
                    <p>
                      When you log in, you can:
                    </p>
                    <ul>
                      <li>Use our repair wizard to get cost estimates and get started with your repairs online</li>
                      <li>Book Virtual Repair Services</li>
                      <li>Schedule pick-ups and deliveries (NYC Only)</li>
                      <li>Set-up mail-in repairs and create instant shipping labels (Worldwide)</li>
                      <li>Pay for services</li>
                      <li>Check the status of your repairs</li>
                      <li>Manage your personal information</li>
                      <li>Receive custom offers on repairs and products (opt-in <FontAwesomeIcon icon={faQuestionCircle} style={{ color: scssVars.orange }} size="sm" /> for Leather Spa mailing list required)</li>
                    </ul>
                    <BodyAddendum className="mb-4">
                      Please Note: The information you provide us is for our use only and will be used solely for the purpose of communicating with you regarding your estimates and repairs.
                    </BodyAddendum>
                  </BodyCopy>

                </Col>
                <Col xs={{ size: 12, order: 1 }} md={{ size: 5, order: 2 }}>
                  <LogInForm from={location.state && location.state.from} />
                </Col>
              </Row>
              <h2 className="text-center mt-4" style={{ fontFamily: 'inherit' }}>Repair More. Buy Less.</h2>
            </Container>
          </section>
        </main>
      </Layout>
    </GuestRoute>
  )
}

LogInPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}
