import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Spinner } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'

import { useUserContext } from '~providers/user'
import { validateEmail } from '~components/Shared/Functions'

const LoginField = styled(Field)`
  font-size: 14px !important;
  border: none !important;
  border-bottom: 1px solid #8a8a8a !important;
  box-shadow: none !important;
  padding: 0 !important;
`

const TextLink = styled(Link)`
  font-style: italic;
  font-weight: 500;
  text-decoration: underline;
`

const PolicyLink = styled(Link)`
  font-size: inherit;
  font-family: inherit;
`

function LogInForm({ from }) {
  const { useLogIn } = useUserContext()
  const [logIn] = useLogIn()

  const onSubmit = async ({ email, password }, actions) => {
    try {
      await logIn(email, password)
      actions.setSubmitting(false)
      navigate(from || '/account')
    } catch (err) {
      actions.setErrors(err)
      actions.setStatus({ msg: err.message })
      actions.setSubmitting(false)
    }
  }

  const validatePassword = (password) => {
    if (!password) return 'Password required'
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={onSubmit}
    >
      {({
        status,
        isValid,
        isSubmitting
      }) => (
        <Form>
          <LoginField
            className="form-control mb-3"
            placeholder="Email address"
            type="email"
            name="email"
            validate={validateEmail}
            required
          />
          <LoginField
            className="form-control mb-3"
            placeholder="Password"
            type="password"
            name="password"
            validate={validatePassword}
            required
          />

          {!isValid && (
            <>
              {['email', 'password'].map((name, i) => (
                <ErrorMessage name={name} key={i}>
                  {errMsg => <Alert color="danger">{errMsg}</Alert>}
                </ErrorMessage>
              ))}
            </>
          )}
          {status && status.msg && <Alert color="danger">{status.msg}</Alert>}

          <div className="text-right mb-3">
            <TextLink to="/forgot-password">Forgot Password?</TextLink>
          </div>
          <p>By logging in, you agree to our <PolicyLink to="/privacy">Privacy Policy</PolicyLink> & <PolicyLink to="/terms-conditions">Terms</PolicyLink></p>
          <button className="btn btn-primary w-100" type="submit" disabled={isSubmitting}>
            {isSubmitting ? <Spinner color="light" /> : 'Log In'}
          </button>
          <div className="my-3">
            <TextLink to="/sign-up">Don&apos;t have an account? Sign up</TextLink>
          </div>
        </Form>
      )}
    </Formik>
  )
}

LogInForm.propTypes = {
  from: PropTypes.string
}

export default LogInForm
